import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

class Navigation {
    constructor (element, options) {
        const defaults = {
            initAttr: 'data-nav',
            rootToggleAttr: 'rootToggle',
            childrenAttr: 'category',
            scrollerAttr: 'scroller',
            subScrollerAttr: 'sub-scroller',
            toggleAttr: 'toggle',
            closeAttr: 'close',
            breakpoint: 1024
        };

        this.settings = Object.assign({}, defaults, options);

        this.$navigation = element;
        this.$navigationToggle = document.querySelector('[' + this.settings.initAttr + '="' + this.settings.rootToggleAttr + '"]');
        this.$navigationScroller = this.$navigation.querySelector('[' + this.settings.initAttr + '="' + this.settings.scrollerAttr + '"]');
        this.$navigationToggles = this.$navigation.querySelectorAll('[' + this.settings.initAttr + '="' + this.settings.toggleAttr + '"]');
        this.$navigationMainWithChildren = this.$navigation.querySelectorAll('[' + this.settings.initAttr + '="' + this.settings.childrenAttr + '"]');
        this.$body = document.querySelector('body');
        this.closeTimer = null;

        this.initialize();
    }

    initialize () {
        this.setEvents();
    }

    setEvents () {
        const breakpoint = window.matchMedia('(min-width: ' + this.settings.breakpoint + 'px)');

        this.$navigation.addEventListener('change', (e) => {
            const $element = e.target;

            if ($element === this.$navigationToggle) {
                if (this.$navigationToggle.checked === true) {
                    this.clearAllSubnavs();
                    disableBodyScroll(this.$navigationScroller);
                } else {
                    enableBodyScroll(this.$navigationScroller);
                }
            }

            if ($element.getAttribute(this.settings.initAttr) === this.settings.toggleAttr) {
                this.$navigationScroller.scrollTo(0, 0);
            }
        });

        for (let i = 0; i < this.$navigationMainWithChildren.length; i++) {
            const $rootItem = this.$navigationMainWithChildren[i];
            const $closeSubnavHandle = $rootItem.querySelector('[' + this.settings.initAttr + '="' + this.settings.closeAttr + '"]');
            const $subScroller = $rootItem.querySelector('[' + this.settings.initAttr + '="' + this.settings.subScrollerAttr + '"]');

            $rootItem.addEventListener('mouseenter', () => {
                if (breakpoint.matches) {
                    this.$body.classList.add('nav--open');
                    $subScroller.scrollTo(0, 0);
                    disableBodyScroll($subScroller);
                }
            });

            $rootItem.addEventListener('mouseleave', () => {
                if (breakpoint.matches) {
                    this.$body.classList.remove('nav--open');
                    this.clearAllSubnavs();
                    enableBodyScroll($subScroller);
                }
            });

            $closeSubnavHandle.addEventListener('click', (e) => {
                const targetId = $closeSubnavHandle.getAttribute('for');
                const $target = document.getElementById(targetId);

                if (breakpoint.matches) {
                    this.$body.classList.remove('nav--open');
                    this.closeMainnav($rootItem);

                    if ($target && $target.checked === false) {
                        e.preventDefault();
                    }
                }
            });
        }

        document.addEventListener('click', (e) => {
            if (breakpoint.matches && e.target.classList.contains('main-nav--level-2')) {
                const $rootItem = e.target.closest('[' + this.settings.initAttr + '="' + this.settings.childrenAttr + '"]');
                this.closeMainnav($rootItem);
            }
        });

        window.addEventListener('resize', () => {
            if (!window.isMobileDevice) {
                this.$navigationToggle.checked = false;
                enableBodyScroll(this.$navigationScroller);
            }
        });
    }

    closeMainnav ($item) {
        clearTimeout(this.closeTimer);
        $item.classList.add('inactive');
        this.closeTimer = window.setTimeout(() => {
            $item.classList.remove('inactive');
        }, 500);
    }

    clearAllSubnavs () {
        for (let i = 0; i < this.$navigationToggles.length; i++) {
            const $toggle = this.$navigationToggles[i];

            if ($toggle.checked === true) {
                $toggle.checked = false;
            }
        }
    }
}

export default Navigation;
